<template>
	<div id="shop">
		<b-row>
			<b-colxx xxs="12" id="step0" class="mb-4" v-if="steps == 0">
				<b-row>
					<b-colxx xxs="12" xs="2" offset-md="4">
						<b-card class="seleccionable" v-on:click="stepTo(1)">
							<div class="glyph-icon simple-icon-location-pin"></div>
							<h2>Comprar dispositivo</h2>
						</b-card>
					</b-colxx>
					<b-colxx xxs="12" xs="2" >
						<b-card v-if="getUser.suscripcionInfo != null && getUser.suscripcionInfo.status != active" class="seleccionable" v-on:click="stepTo(2)">
							<div class="glyph-icon simple-icon-refresh"></div>
							<h2>Renovar suscripción</h2>
						</b-card>
						<b-card v-if="getUser.suscripcionInfo == null" class="seleccionable" v-on:click="stepTo(2)">
							<div class="glyph-icon simple-icon-refresh"></div>
							<h2>Contratar plan</h2>
						</b-card>
						<b-card v-if="getUser.suscripcionInfo != null && getUser.suscripcionInfo.status == active" class="seleccionable" v-on:click="stepTo(2)">
							<div class="glyph-icon simple-icon-refresh"></div>
							<h2>Cambiar plan</h2>
						</b-card>
					</b-colxx>
					<b-colxx xxs="12" xs="12" v-if="dataArray.length > 0">
						<b-card class="mt-4">
							<h2>Historial de pedidos</h2>
							<b-table
					            ref="custom-table"
					            class="vuetable"
					            sort-by="title" sort-desc.sync="false"
					            @row-selected="rowSelected"
					            selectable
					            :select-mode="bootstrapTable.selectMode"
					            :current-page="currentPage"
					            selectedVariant="primary"
					            :fields="bootstrapTable.fields"
					            :items="dataArray"
					            :per-page="perPage"
					          >
					            <template slot="status" slot-scope="data">
					              <b-badge class="mb-1" :variant="data.item.statusColor">{{ data.value }}</b-badge>
					            </template>
				        	</b-table>
					        <b-pagination
					            size="sm"
					            align="center"
					            :total-rows="totalRows"
					            :per-page="perPage"
					            v-model="currentPage"
					          >
					            <template v-slot:next-text>
					              <i class="simple-icon-arrow-right"/>
					            </template>
					            <template v-slot:prev-text>
					              <i class="simple-icon-arrow-left"/>
					            </template>
					            <template v-slot:first-text>
					              <i class="simple-icon-control-start"/>
					            </template>
					            <template v-slot:last-text>
					              <i class="simple-icon-control-end"/>
					            </template>
				          	</b-pagination>
				        </b-card>
					</b-colxx>
					<b-colxx xxs="12" xs="12" class="mt-4" v-if="bootstrapTable.selected.length>0">
						<b-card :title="$t('Detalle Pedido')" >
						    <b-colxx xxs="12">
						        
					          <b-form @submit.prevent="onGridFormSubmit">
					            <b-row>

					              	<b-colxx sm="2">
					                	<b-form-group :label="$t('Folio')">
					                  		<b-form-input readonly type="text" v-model="selected.id" />
					                	</b-form-group>
					              	</b-colxx>

					              	<b-colxx sm="4">
					                	<b-form-group :label="$t('Fecha')">
					                  		<b-form-input readonly v-bind:value="formatoFecha(selected.fechaCreacion)"  ></b-form-input>
					                	</b-form-group>
					              	</b-colxx>

					              	<b-colxx sm="12">
					              		<b-table :items="selected.detalle"
					              		   :fields="detallePedidoFields">
					              		</b-table>
					              		<p class="txt-right"><b>Total:</b> {{selected.total}}</p>
					              	</b-colxx>
					            
					            </b-row>
					          </b-form>
						    </b-colxx>
	  
				      	</b-card>
					</b-colxx>
				</b-row>
			</b-colxx>

			<b-colxx xxs="12" id="step-disp" v-if="steps == 1">
				<b-card :title="$t('Seleccione el dispositivo que desea comprar')">
					<b-row>
						<b-colxx v-for="product in shopProducts" xxs="12" xs="12"sm="6" md="2" lg="2" >
							<b-card v-on:click="addSelected(product)" class="product">
								<img v-bind:src="product.img">
								<figcaption>{{product.nombre}}</figcaption>
								<p>{{product.descripcion}}</p>
								<p><b>${{product.costo}}</b></p>
								<button class="btn btn-success default">Agregar</button>
							</b-card>
						</b-colxx>
					</b-row>
					<b-row class="mt-4">
						<b-colxx xxs="12" class="carritoContainer">
							<h2>Mi carrito</h2>
					        <b-table hover 
					        	:items="selectedProducts" 
					        	:fields="tableFields">
					        	<template v-slot:cell(cantidad)="row">
							        <input type="text" name="" v-model="row.item.cantidad" >
							    </template>
				        		<template v-slot:cell(actions)="row">
							        <b-button size="sm" @click="deleteSelected(row.item)" class="btn mb-1 btn-danger default">
							          <div class="glyph-icon simple-icon-minus"></div>
							        </b-button>
							    </template>
				        	</b-table>

					    </b-colxx>
					</b-row>
					<div class="buttons">
						<button class="btn mb-1 btn-light default back" v-on:click="stepTo(0)">Atras</button>
						<button v-if="selectedProducts.length > 0" class="btn mb-1 btn-primary default next" v-on:click="stepTo(3)">Siguiente</button>
					</div>
				</b-card>
			</b-colxx>

			<b-colxx xxs="12" id="step-susc" v-if="steps == 2">
				<b-card :title="$t('Seleccione el plan que desea contratar')">
					<b-row>
						<b-colxx v-for="plan in membershipPlans"  xxs="12" xs="12"sm="6" md="2" lg="2" >
							<b-card v-on:click="addSelectedPlan(plan)" class="plan">
								<img v-bind:src="plan.img">
								<figcaption>{{plan.nombre}}</figcaption>
								<p>Cobertura hasta {{plan.numDispositivos}} dispositivo{{plan.numDispositivos > 1 ? 's':''}} y con una duración de {{plan.repetirCada}} {{plan.unidadEspanol}}</p>
								<p><b>${{plan.cantidad}}</b></p>
								<button class="btn btn-success default">Seleccionar</button>
							</b-card>
						</b-colxx>
					</b-row>
					<b-row v-if="selectedPlans.length > 0" class="detallePlan">
						<b-colxx xxs="12" xs="4" lg="7">
								<img v-bind:src="selectedPlans[0].img">
						</b-colxx>
						<b-colxx xxs="12" xs="4" lg="7" class="descripcion">
								<h2>{{selectedPlans[0].nombre}}</h2>
								<!--<p>{{selectedPlans[0].descripcion}}</p>-->
								<p>Cobertura hasta {{selectedPlans[0].numDispositivos}} dispositivo{{selectedPlans[0].numDispositivos > 1 ? 's':''}} y con una duración de {{selectedPlans[0].repetirCada}} {{selectedPlans[0].unidadEspanol}}</p>
						</b-colxx>
					</b-row>
					<div class="buttons">
						<button class="btn mb-1 btn-light default back" v-on:click="stepTo(0)">Atras</button>
						<button v-if="selectedPlans.length > 0" class="btn mb-1 btn-primary default next" v-on:click="stepTo(3)">Continuar</button>
					</div>
				</b-card>
			</b-colxx>

			<b-row class="col-sm-12" v-if="steps == 3">
				<b-colxx xxs="12" sm="7" id="checkout" >
				  	<b-card class="mb-5" :title="$t('Dirección de envío')" v-if="banderaPaso == 'producto'">
				  		<div class="row" id="tablaDirecciones">
				      		<template v-if="direccionesList.length > 0" v-for="direccion in direccionesList">
				      			<b-colxx xxs="12" sm="4">
					      			<b-card class="direccionCard" v-bind:class="{selected: direccionSelected.id == direccion.id ? true : false}" xxs="3" v-on:click="seleccionarDireccion(direccion)">
					      				<p class="mt-1 mb-0">{{direccion.calle}} {{direccion.numExt}} {{direccion.numInt}}</p>
					      				<p class="mb-0">Entre calles {{direccion.entreCalle}} y {{direccion.entreCalle2}}</p>			      				
					      				<p class="mb-0">{{direccion.codigoPostal}}</p>
					      				<p class="mb-0">{{direccion.colonia}}, {{direccion.municipio}}, {{direccion.estadoGeografico}}, {{direccion.pais}}</p>
					      				<p class="mb-1"><strong>Información adicional:</strong> {{direccion.infoAdicional}}</p>
					      				<span class="eliminarDireccion" v-on:click="eliminarDireccion(direccion)"><div class="glyph-icon iconsminds-close"></div></span>
					      			</b-card>
					      		</b-colxx>
				      		</template>
				      		<b-colxx xxs="12" sm="4">		      			
				      			<button  variant="primary" class="btn mb-1 btn-light default back addDirection" v-b-modal.modalDireccion>Agregar nueva dirección</button>
				      		</b-colxx>
			      		</div>
			      	</b-card>

			      	<!--pick card-->
			      	<b-card class="mb-5" :title="$t('Tarjetas guardadas')" >
				  		<div class="row" id="tablaDirecciones">
				      		<template v-if="tarjetasCliente.length > 0" v-for="tarjeta in tarjetasCliente">
				      			<b-colxx xxs="12" sm="4">
					      			<b-card class="direccionCard" v-bind:class="{selected: tarjetaSelected.id == tarjeta.id ? true : false}" xxs="3" v-on:click="seleccionarTarjeta(tarjeta)">
					      				<p class="mt-1 mb-0"><strong>{{tarjeta.nombreTarjetaHabiente}}</strong></p>
					      				<p class="mb-0">{{tarjeta.numeroTarjeta}}</p>
					      				<p class="mb-0">{{tarjeta.marca}}</p>
					      			</b-card>
					      		</b-colxx>
				      		</template>
				      		<b-colxx v-if="tarjetasCliente.length > 0" xxs="12" sm="4">		      			
				      			<button  v-if="tarjetasCliente.length < 3" variant="primary" class="btn mb-1 btn-light default back addDirection"  v-on:click="desSeleccionarTarjeta()">Agregar nueva tarjeta</button>
				      			<button  v-if="tarjetasCliente.length >= 3" variant="primary" class="btn mb-1 btn-light default back addDirection"  v-on:click="desSeleccionarTarjeta()">Utilizar otra tarjeta</button>
				      		</b-colxx>
				      		<b-colxx v-if="tarjetasCliente.length <= 0" xxs="12">
				      			<h3>No cuenta con tarjetas guardadas</h3>
				      		</b-colxx>
			      		</div>
			      	</b-card>
			      	
			      	<b-card class="mb-5" :title="$t('Datos de tarjeta')">
			            <b-row>
			            	<b-colxx v-if="tarjetaSelected.id < 0" sm="6" md="6" >
				                <b-form-group :label="$t('Nombre')">
				                  <b-form-input v-model="checkoutForm.nombre" name="nombre"></b-form-input>
				                </b-form-group>
				            </b-colxx>
				            <b-colxx v-if="tarjetaSelected.id < 0" sm="6" md="6">
			            		
				                <b-form-group :label="$t('Apellidos')">
				                  <b-form-input v-model="checkoutForm.apellidos" name="apellidos"></b-form-input>
				                </b-form-group>
				            </b-colxx>
				            <b-colxx v-if="tarjetaSelected.id < 0" sm="12" md="12">
				                <b-form-group :label="$t('Número de tarjeta')">
				                  <b-form-input :formatter="formatterCard" v-model="checkoutForm.cardNumber" name="card_number"></b-form-input>
				                  <img id="logoVisaPlaceholder" src="/static/img/kit_openpay/visa.png">
				                </b-form-group>
				            </b-colxx>
				            <b-colxx v-if="tarjetaSelected.id < 0" sm="4" class="row" >
				            	<h6 class="col-xs-12" style="font-size: inherit;">Fecha de vencimiento</h6>
				                <b-form-group class="col-md-6">
				                  <b-form-input :formatter="formatterMonthYear" v-model="checkoutForm.expiremonth" placeholder="MM" name="month"></b-form-input>
				                </b-form-group>
				                <b-form-group class="col-md-6">
				                	<b-form-input :formatter="formatterMonthYear" placeholder="YY" v-model="checkoutForm.expireyear" name="year"></b-form-input>
				                </b-form-group>
				            </b-colxx>
				            <b-colxx v-if="tarjetaSelected.id < 0" sm="2">
				                <b-form-group :label="$t('CVV')">
				                  <b-form-input  :formatter="formatterCVV" v-model="checkoutForm.ccv" ></b-form-input>
				                </b-form-group>
				            </b-colxx>
				            <b-colxx v-if="tarjetaSelected.id < 0" sm="6" class="casilla">
			            		<b-form-group>
			            			<b-form-checkbox v-model="checkoutForm.guardarTarjeta" >
			            				Guardar datos de tarjeta para futuras compras
			            			</b-form-checkbox>
			            		</b-form-group>
			            	</b-colxx>
			            	<b-colxx sm="12">
			            		<b-form-group class="openpaylogo">
			            			<img  src="/static/img/kit_openpay/openpay_color.png">
			            		</b-form-group>
			            	</b-colxx>
				            <b-colxx sm="12" class="casilla">
			            		<b-form-group>
			            			<b-form-checkbox v-model="banderaFacturacion" >
			            				Marque esta casilla si requiere factura para esta compra
			            			</b-form-checkbox>
			            		</b-form-group>
			            	</b-colxx>
			            	<b-colxx sm="12" class="casilla">
			            		<b-form-group>
			            			<b-form-checkbox v-model="checkoutForm.is3dSecure" >
			            				Realizar pago 3D-secure
			            			</b-form-checkbox>
			            		</b-form-group>
			            	</b-colxx>
			            </b-row>			            
			      	</b-card>
			      	<b-card v-if="banderaFacturacion == true" :title="$t('Datos de facturación')">
			      		<b-row>
			            	
			            	<b-colxx sm="12">
			            		<b-form-group :label="$t('Razón social')">
			            			<b-form-input v-model="checkoutForm.facturacion.nombre_razonSocial" name="razon_social"></b-form-input>
			            		</b-form-group>
			            	</b-colxx>
			            	<b-colxx sm="6">
			            		<b-form-group :label="$t('Tipo de persona')">
			            			<b-form-select placeholder="Seleccione una opción" v-model="checkoutForm.facturacion.personaFisicaMoral" :options="select.optionFisicaMoral" name="persona_fiscal"></b-form-select>
			            		</b-form-group>
			            	</b-colxx>
			            	<b-colxx sm="6">
			            		<b-form-group :label="$t('RFC')">
			            			<b-form-input v-model="checkoutForm.facturacion.rfc" placeholder="" name="rfc" ></b-form-input>
			            		</b-form-group>
			            	</b-colxx>
			            	<b-colxx sm="6">
			            		<b-form-group :label="$t('Calle')">
			            			<b-form-input v-model="checkoutForm.facturacion.calle" placeholder="" name="calle"></b-form-input>
			            		</b-form-group>
			            	</b-colxx>
			            	<b-colxx sm="3">
			            		<b-form-group :label="$t('Número interior')">
			            			<b-form-input v-model="checkoutForm.facturacion.numInt" placeholder="" name="numInt"></b-form-input>
			            		</b-form-group>
			            	</b-colxx>
			            	<b-colxx sm="3">
			            		<b-form-group :label="$t('Número exterior')">
			            			<b-form-input v-model="checkoutForm.facturacion.numExt" placeholder="" name="numExt"></b-form-input>
			            		</b-form-group>
			            	</b-colxx>
			            	<b-colxx sm="6">
			            		<b-form-group :label="$t('Colonia')">
			            			<b-form-input v-model="checkoutForm.facturacion.colonia" placeholder="" name="colonia"></b-form-input>
			            		</b-form-group>
			            	</b-colxx>
			            	<b-colxx sm="6">
			            		<b-form-group :label="$t('Municipio')">
			            			<b-form-input v-model="checkoutForm.facturacion.municipio" placeholder="" name="municipio"></b-form-input>
			            		</b-form-group>
			            	</b-colxx>
			            	<b-colxx sm="6">
			            		<b-form-group :label="$t('Estado')">
			            			<b-form-select placeholder="Seleccione un estado" v-model="checkoutForm.facturacion.estadoGeografico" :options="select.estadosmexico" name="estado"></b-form-select>
			            		</b-form-group>
			            	</b-colxx>
			            	<b-colxx sm="3">
			            		<b-form-group :label="$t('Código postal')">
			            			<b-form-input v-model="checkoutForm.facturacion.codigoPostal" placeholder="" ></b-form-input>
			            		</b-form-group>
			            	</b-colxx>
			            	<b-colxx sm="3">
			            		<b-form-group :label="$t('Teléfono')">
			            			<b-form-input v-model="checkoutForm.facturacion.telefono" placeholder="" name="telefono"></b-form-input>
			            		</b-form-group>
			            	</b-colxx>
			            	
			            </b-row>
			      	</b-card>
				</b-colxx>

				<b-colxx xxs="12" sm="5" id="carrito" >
					<b-card class="row sticky" v-if="banderaPaso == 'producto'">
						<h3>Carrito de compras</h3>
						<hr>
						<article v-for="product in selectedProducts">
							<img :src="product.img">
							<span>{{product.nombre}}</span>
							<!--<input type="number" name="cantidad" v-model="product.cantidad" min="1">-->
							<b-form-input type="text" v-model="product.cantidad" v-on:keyup="actualizaCantidades()" min="1"></b-form-input>
							<hr>
						</article>
							<hr>
						<div>
							<p><strong>Subtotal:</strong> {{subtotalPedidoData}}</p>
							<p><strong>Envío:</strong> {{envioPedido}}</p>
							<p><strong>Total:</strong> {{subtotalPedidoData + envioPedido}}</p>
						</div>
						<hr>
						<b-button type="button" v-on:click="stepTo(1);" variant="primary" class="btn mb-1 btn-light default back mt-4">Regresar</b-button>
						<b-button v-if="direccionSelected.id > -1 && (tarjetaSelected.id > -1 || tarjetaFormValidate() == true)" type="button" v-on:click="checkout();" variant="primary" class="mt-4 default ml-1 mb-1">Realizar compra</b-button>
					</b-card>
					<b-card class="row sticky" v-if="banderaPaso == 'suscripcion'">
						<h3>Plan contratado</h3>
						<hr>
						<article v-for="plan in selectedPlans">
							<img :src="plan.img">
							<span>{{plan.nombre}}</span>
							<p>{{plan.descripcion}}</p>
							<p>Cobertura hasta {{plan.numDispositivos}} dispositivo{{plan.numDispositivos > 1 ? 's':''}} y con una duración de {{plan.repetirCada}} {{plan.unidadEspanol}}</p>
							<hr>
						</article>
							<hr>
						<div>
							<p><strong>Subtotal:</strong> {{subtotalPedidoData}}</p>
							<p><strong>Total:</strong> {{subtotalPedidoData}}</p>
						</div>
						<hr>
				    	<b-button type="button" v-on:click="stepTo(2);" variant="primary" class="btn mb-1 btn-light default back mt-4">Regresar</b-button>
				    	<b-button type="button" v-on:click="checkoutSuscription();" variant="primary" class="mt-4">Confirmar suscripción</b-button>
					</b-card>
				</b-colxx>
			</b-row>

			<b-colxx xxs="12" id="step-thanks" v-if="steps == 4">
				<!--<b-card>-->
					<section id="msgGracias" v-if="banderaPaso == 'producto'">
						<h1>¡Muchas gracias por su compra!</h1>
						<hr>
						<div class="row invoice">
			                <!--<div class="col-12">-->
			                    <div class="invoice-contents col-12" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0"
			                        offset="0"
			                        style="background-color:#ffffff; max-width:830px; font-family: Helvetica,Arial,sans-serif !important; position: relative;">
			                        <table bgcolor="#ffffff" border="0" cellpadding="0" cellspacing="0"
			                            class="tableStyles"
			                            heigth="auto">

			                            <tbody>
			                                <tr>
			                                    <td class="imgCell" align="left" valign="center">
			                                        <img id="logoGracias" src="/static/img/logo-sole.svg">
			                                    </td>
			                                    <td class="imgCell" align="right" valign="center"
			                                        >
			                                        <p class="headerInvoice">
			                                            Soluciones Médicas del Pacífico<br> Boulevard Miguel de la Madrid Hurtado km. 13-1.<br> Centro Salagua, Manzanillo, Colima, México.<br>33 2550 7867
			                                        </p>
			                                    </td>
			                                </tr>
			                                <tr>
			                                    <td colspan="2" style="padding-top:30px; border-top:1px solid #f1f0f0">
			                                        <!--<table style="width: 100%;">
			                                            <tbody>
			                                                <tr>
			                                                    <td
			                                                        style="vertical-align:middle; border-radius: 3px; padding:30px; background-color: #f9f9f9; border-right: 5px solid white;">
			                                                        <p
			                                                            style="color:#303030; font-size: 14px;  line-height: 1.6; margin:0; padding:0;">
			                                                            Latashia Nagy<br>100-148 Warwick Trfy, Kansas City, USA
			                                                        </p>
			                                                    </td>

			                                                    <td
			                                                        style="text-align: right; padding-top:0px; padding-bottom:0; vertical-align:middle; padding:30px; background-color: #f9f9f9; border-radius: 3px; border-left: 5px solid white;">
			                                                        <p
			                                                            style="color:#8f8f8f; font-size: 14px; padding: 0; line-height: 1.6; margin:0; ">
			                                                            Invoice #: 741<br>
			                                                            02.02.2019
			                                                        </p>
			                                                    </td>
			                                                </tr>
			                                            </tbody>
			                                        </table>--><!--En caso de factura, validar-->
			                                        <table style="width: 100%; margin-top:40px;">
			                                            <thead>
			                                                <tr>
			                                                    <th
			                                                        style="text-align:center; font-size: 10px; color:#8f8f8f; padding-bottom: 15px;">
			                                                        PRODUCTO
			                                                    </th>
			                                                    <th
			                                                        style="text-align:center; font-size: 10px; color:#8f8f8f; padding-bottom: 15px;">
			                                                        CANTIDAD
			                                                    </th>
			                                                    <th
			                                                        style="text-align:right; font-size: 10px; color:#8f8f8f; padding-bottom: 15px;">
			                                                        PRECIO
			                                                    </th>
			                                                </tr>
			                                            </thead>
			                                            <tbody>
			                                                <tr v-for="product in selectedProducts">
			                                                    <td style="padding-top:0px; padding-bottom:5px;">
			                                                        <h4
			                                                            style="font-size: 16px; line-height: 1; margin-bottom:0; color:#303030; font-weight:500; margin-top: 10px;">
			                                                            {{product.nombre}}</h4>
			                                                    </td>
			                                                    <td>
			                                                        <p href="#"
			                                                            style="font-size: 13px; text-decoration: none; line-height: 1; color:#909090; margin-top:0px; margin-bottom:0;">
			                                                            {{product.cantidad}}</p>
			                                                    </td>
			                                                    <td style="padding-top:0px; padding-bottom:0; text-align: right;">
			                                                        <p
			                                                            style="font-size: 13px; line-height: 1; color:#303030; margin-bottom:0; margin-top:0; vertical-align:top; white-space:nowrap;">
			                                                            $
			                                                            {{product.costo}}</p>
			                                                    </td>
			                                                </tr>
			                                            </tbody>
			                                        </table>
			                                    </td>
			                                </tr>
			                            </tbody>
			                        </table>

			                        <table bgcolor="#ffffff" border="0" cellpadding="0" cellspacing="0"
			                            style="position:relative; bottom:0; width:100%; background-color:#ffffff;border-collapse:separate !important; border-spacing:0;color:#242128; margin:0;padding:30px; padding-top: 20px;"
			                            heigth="auto">
			                            <tr>
			                                <td colspan="3" style="border-top:1px solid #f1f0f0">&nbsp;</td>
			                            </tr>
			                            <tr>
			                                <td colspan="2" style="width: 100%">
			                                    <p href="#"
			                                        style="font-size: 13px; text-decoration: none; line-height: 1.6; color:#909090; margin-top:0px; margin-bottom:0; text-align: right;">
			                                        Subtotal : </p>
			                                </td>
			                                <td style="padding-top:0px; text-align: right;">
			                                    <p
			                                        style="font-size: 13px; line-height: 1.6; color:#303030; margin-bottom:0; margin-top:0; vertical-align:top; white-space:nowrap; margin-left:15px">
			                                        $
			                                        {{subtotalPedidoData}}</p>
			                                </td>
			                            </tr>
			                            <!--<tr>
			                                <td colspan="2" style="width: 100%">
			                                    <p href="#"
			                                        style="font-size: 13px; text-decoration: none; line-height: 1.6; color:#909090; margin-top:0px; margin-bottom:0; text-align: right;">
			                                        Tax : </p>
			                                </td>
			                                <td style="padding-top:0px; text-align: right;">
			                                    <p
			                                        style="font-size: 13px; line-height: 1.6; color:#303030; margin-bottom:0; margin-top:0; vertical-align:top; white-space:nowrap; margin-left:15px">
			                                        $
			                                        2.18</p>
			                                </td>
			                            </tr>-->
			                            <tr>
			                                <td colspan="2" style="width: 100%">
			                                    <p href="#"
			                                        style="font-size: 13px; text-decoration: none; line-height: 1.6; color:#909090; margin-top:0px; margin-bottom:0; text-align: right;">
			                                        Envío : </p>
			                                </td>
			                                <td style="padding-top:0px; text-align: right;">
			                                    <p
			                                        style="font-size: 13px; line-height: 1.6; color:#303030; margin-bottom:0; margin-top:0; vertical-align:top; white-space:nowrap; margin-left:15px">
			                                        $100</p>
			                                </td>
			                            </tr>
			                            <tr>
			                                <td colspan="2" style=" width: 100%; padding-bottom:15px;">
			                                    <p href="#"
			                                        style="font-size: 13px; text-decoration: none; line-height: 1.6; color:#909090; margin-top:0px; margin-bottom:0; text-align: right;">
			                                        <strong>Total : </strong></p>
			                                </td>
			                                <td style="padding-top:0px; text-align: right; padding-bottom:15px;">
			                                    <p
			                                        style="font-size: 13px; line-height: 1.6; color:#303030; margin-bottom:0; margin-top:0; vertical-align:top; white-space:nowrap; margin-left:15px">
			                                        <strong>${{subtotalPedidoData + 100}}</strong></p>
			                                </td>
			                            </tr>
			                            <tr>
			                                <td colspan="3" style="border-top:1px solid #f1f0f0">&nbsp;</td>
			                            </tr>
			                            <tr>
			                                <td colspan="3" style="text-align:center;">
			                                    <p style="color: #909090; font-size:11px; text-align:center;"></p>
			                                </td>
			                            </tr>
			                        </table>
			                    </div>
			                <!--</div>-->
			            </div>

						<p>En cuanto su pedido esté listo se le proporcionará el código de seguimiento del envío</p>
						<h3>Si tienes alguna duda o necesitas más información respecto a tu compra contáctanos</h3>
						<p><a href="https://api.whatsapp.com/send?phone=5213331015825"><img src="/static/img/WhatsApp_Logo_3.svg" id="whatsappLogo"> 33-3101-5825</a> o en nuestro <a href="/asistencia">Formulario de contacto</a></p>
						<hr>
						<img id="logoGracias" src="/static/img/logo-sole.svg">
						<div class="mt-5">
							<router-link class="btn btn-success" to="/plataforma/mapa">
								Regresar
							</router-link>
						</div>
					</section>
					<section id="msgGracias" v-if="banderaPaso == 'suscripcion'">
						<h1>¡Muchas gracias por su compra!</h1>
						<p>La información de la suscripción será enviada a {{getUser.usuario}}</p>
						<p>Será necesario acceder a la sección "Suscripciones" para elegir los dispositivos que aplican a esta renovación </p>
						<h3>Si tienes alguna duda o necesitas más información respecto a tu compra contáctanos</h3>
						<p><a href="https://api.whatsapp.com/send?phone=5213331015825"><img src="/static/img/WhatsApp_Logo_3.svg" id="whatsappLogo"> 33-3101-5825</a> o en nuestro <a href="/asistencia">Formulario de contacto</a></p>
						<hr>
						<img id="logoGracias" src="/static/img/logo-sole.svg">
						<div class="mt-5">
							<router-link class="btn btn-success" to="/plataforma/mapa">
								Regresar
							</router-link>
						</div>
					</section>
				<!--</b-card>-->
			</b-colxx>

		</b-row>
		<b-modal id="modalDireccion" ref="modalDireccion" :title="$t('Registrar dirección de envío')">
	      <b-row>
	        <b-colxx xxs="12">
	            <!--<b-card class="mb-4" :title="$t('Editar dispositivo')">-->
	              <b-form >
	                <b-row>
	                  	<b-colxx sm="7">
	                    	<b-form-group :label="$t('Calle *')">
	                      		<b-form-input v-model="nuevaDireccion.calle" ></b-form-input>
	                    	</b-form-group>
	                  	</b-colxx>

 						<b-colxx sm="3">
		                    <b-form-group :label="$t('N°.Exterior *')">
		                    	<b-form-input v-model="nuevaDireccion.numExt" ></b-form-input>
		                    </b-form-group>
		                </b-colxx>
		                <b-colxx sm="2">
		                    <b-form-group :label="$t('N°.Interior')">
		                    	<b-form-input v-model="nuevaDireccion.numInt" ></b-form-input>
		                    </b-form-group>
		                </b-colxx>

	                    <b-colxx sm="4">
	                      <b-form-group :label="$t('Colonia')">
	                        <b-form-input v-model="nuevaDireccion.colonia" ></b-form-input>
	                      </b-form-group>
	                    </b-colxx>

	                    <b-colxx sm="4">
	                      <b-form-group :label="$t('Municipio')">
	                        <b-form-input v-model="nuevaDireccion.municipio" ></b-form-input>
	                      </b-form-group>
	                    </b-colxx>

	                    <b-colxx sm="4">
	                      <b-form-group :label="$t('Estado *')">
	                        <b-form-select v-model="nuevaDireccion.estadoGeografico" :options="select.estadosmexico"></b-form-select>
	                      </b-form-group>
	                    </b-colxx>

	                    <b-colxx sm="6">
	                      <b-form-group :label="$t('Código postal *')">
	                        <b-form-input v-model="nuevaDireccion.codigoPostal" ></b-form-input>
	                      </b-form-group>
	                    </b-colxx>

	                    <b-colxx sm="6">
	                      <b-form-group :label="$t('País')">
	                        <b-form-select v-model="nuevaDireccion.pais" :options="select.opcionesPais"></b-form-select>
	                      </b-form-group>
	                    </b-colxx>

	                    <b-colxx sm="6">
	                      <b-form-group :label="$t('Entre calle: ')">
	                        <b-form-input v-model="nuevaDireccion.entreCalle" ></b-form-input>
	                      </b-form-group>
	                    </b-colxx>

	                    <b-colxx sm="6">
	                      <b-form-group :label="$t('Y calle:')">
	                        <b-form-input v-model="nuevaDireccion.entreCalle2" ></b-form-input>
	                      </b-form-group>
	                    </b-colxx>

	                    <b-colxx sm="12">
	                      <b-form-group :label="$t('Información adicional')">
	                        <b-form-textarea v-model="nuevaDireccion.infoAdicional" ></b-form-textarea>
	                      </b-form-group>
	                    </b-colxx>
	                </b-row>
	              </b-form>
	            <!--</b-card>-->
	        </b-colxx>
	      </b-row>
	      <template slot="modal-footer">
	          <b-button variant="primary" @click="registrarDireccion()" class="mr-1">Guardar</b-button>
	          <b-button variant="secondary" @click="hideModal('modalDireccion')">Cancel</b-button>
	      </template>
	  </b-modal>
	  <b-modal id="modalTarjeta" ref="modalTarjeta" :title="$t('Registrar nueva tarjeta')">
	      <b-row>
	        <b-colxx xxs="12">
	            <!--<b-card class="mb-4" :title="$t('Editar dispositivo')">-->
	              <b-form >
	                <b-row>
	                  	<b-colxx sm="6" md="6" >
			                <b-form-group :label="$t('Nombre')">
			                  <b-form-input v-model="checkoutForm.nombre" name="nombre"></b-form-input>
			                </b-form-group>
			            </b-colxx>
			            <b-colxx sm="6" md="6">
		            		
			                <b-form-group :label="$t('Apellidos')">
			                  <b-form-input v-model="checkoutForm.apellidos" name="apellidos"></b-form-input>
			                </b-form-group>
			            </b-colxx>
			            <b-colxx sm="12" md="12">
			                <b-form-group :label="$t('Número de tarjeta')">
			                  <b-form-input v-model="checkoutForm.cardNumber" name="card_number"></b-form-input>
			                </b-form-group>
			            </b-colxx>
			            <b-colxx sm="6" class="row" >
			            	<h6 sm="12" style="font-size: inherit;">Fecha de vencimiento</h6>
			                <b-form-group class="col-md-6">
			                  <b-form-input v-model="checkoutForm.expiremonth" placeholder="MM" name="month"></b-form-input>
			                </b-form-group>
			                <b-form-group class="col-md-6">
			                	<b-form-input placeholder="YY" v-model="checkoutForm.expireyear" name="year"></b-form-input>
			                </b-form-group>
			            </b-colxx>
			            <b-colxx sm="6">
			                <b-form-group :label="$t('CCV')">
			                  <b-form-input v-model="checkoutForm.ccv" ></b-form-input>
			                </b-form-group>
			            </b-colxx>
			            
	                </b-row>
	              </b-form>
	            <!--</b-card>-->
	        </b-colxx>
	      </b-row>
	      <template slot="modal-footer">
	          <b-button variant="primary" @click="registrarDireccion()" class="mr-1">Guardar</b-button>
	          <b-button variant="secondary" @click="hideModal('modalTarjeta')">Cancelar</b-button>
	      </template>
	  </b-modal>
	</div>
</template>
<script>
import { request, mapActions} from 'vuex';
import { openpayData } from "../../../constants/config";

	export default{
		data(){
			return{
				banderaFacturacion: false,
				banderaPaso: "",
			    checkoutForm: {
			        correo: '',
			        nombre: '',
			        apellidos: '',
			        direccion: '',
			        localidad: '',
			        codigoPostal: '',
			        pais: '',
			        telefono: '',
			        cardNumber: '',
			        expireyear: '',
			        expiremonth:'',
			        ccv: '',
			        facturacion:{
			        	personaFisicaMoral:"",//Select Física o Moral
			        	nombre_razonSocial:"",
			        	calle:"",
			        	numInt:"",
			        	numExt:"",
			        	colonia:"",
			        	municipio:"",
			        	estadoGeografico:"",
			        	codigoPostal:"",
			        	pais:"",
			        	telefono:"",
			        	rfc:""
			        },
			        guardarTarjeta: false,
			        is3dSecure: false
			    },
			    direccionesList: [],
			    tarjetasCliente: [],
			    nuevaDireccion: {
			    	calle: "",
			    	numInt:"",
					numExt:"",
				
					colonia:"",
					municipio:"",
					estadoGeografico:"",
					codigoPostal:"",
					pais:"México",
					entreCalle:"",
					entreCalle2:"",
					infoAdicional:"",
			    },
			    select: {
			    	optionFisicaMoral: [{value:"Física", text:"Física"}, {value:"Moral", text:"Moral"}],
			    	estadosmexico: [{value:'Aguascalientes', text:'Aguascalientes'},
						{value:'Baja California', text:'Baja California'},
						{value:'Baja California Sur', text:'Baja California Sur'},
						{value:'Campeche', text:'Campeche'},
						{value:'Coahuila', text:'Coahuila'},
						{value:'Colima', text:'Colima'},
						{value:'Chiapas', text:'Chiapas'},
						{value:'Chihuahua', text:'Chihuahua'},
						{value:'Ciudad de México', text:'Ciudad de México'},
						{value:'Durango', text:'Durango'},
						{value:'Guanajuato', text:'Guanajuato'},
						{value:'Guerrero', text:'Guerrero'},
						{value:'Hidalgo', text:'Hidalgo'},
						{value:'Jalisco', text:'Jalisco'},
						{value:'Estado de México', text:'Estado de México'},
						{value:'Michoacán de Ocampo', text:'Michoacán de Ocampo'},
						{value:'Morelos', text:'Morelos'},
						{value:'Nayarit', text:'Nayarit'},
						{value:'Nuevo León', text:'Nuevo León'},
						{value:'Oaxaca', text:'Oaxaca'},
						{value:'Puebla', text:'Puebla'},
						{value:'Querétaro', text:'Querétaro'},
						{value:'Quintana Roo', text:'Quintana Roo'},
						{value:'San Luis Potosí', text:'San Luis Potosí'},
						{value:'Sinaloa', text:'Sinaloa'},
						{value:'Sonora', text:'Sonora'},
						{value:'Tabasco', text:'Tabasco'},
						{value:'Tamaulipas', text:'Tamaulipas'},
						{value:'Tlaxcala', text:'Tlaxcala'},
						{value:'Veracruz', text:'Veracruz'},
						{value:'Yucatán', text:'Yucatán'},
						{value:'Zacatecas', text:'Zacatecas'}],
					opcionesPais: [{
						value: 'México', text: 'México'
					}]
			    },
			    steps: 0,
			    shopProducts: [],
			    selectedProducts: [],
			    direccionSelected: {
			    	id: -1
			    },
			    tarjetaSelected: {
			    	id: -1
			    },
			    membershipPlans: [
			    	/*{id: 1, img:"https://picsum.photos/200", nombre: "Plan A", descripcion: "Con este plan cubre hasta 2 de tus dispositivos", costo:400.00},
			    	{id: 2, img:"https://picsum.photos/200", nombre: "Plan B", descripcion: "Con este plan cubre hasta 5 de tus dispositivos", costo:500.00},*/
			    ],
			    selectedPlans:[],
			    tableFields: [
			    	{key:'nombre', label:'Producto'},
			    	{key:'cantidad', label:'Cantidad'},
			    	{key: 'actions', label:'Eliminar'}
			    ],
			    envioPedido: 100.0,
			    subtotalPedidoData : 0.0,

			    //PEDIDOS TABLE
			    dataArray: [],
			    bootstrapTable: {
			        selected: [],
			        selectMode: 'single',
			        fields: [
			          	{ key: 'id', label: 'Folio', sortable: true, sortDirection: 'desc', tdClass: 'list-item-heading' },
			          	{ key: 'fechaCreacion', label: 'Fecha', sortable: true, tdClass: 'text-muted', formatter:  function(valueFecha){
												return new Date(valueFecha).toLocaleString("es-MX");
											}
						},
			          	{ key: 'estado', label: 'Estado', sortable: true, tdClass: 'text-muted' },
			        ]
			    },
			    currentPage: 1,
			    perPage: 5,
			    detallePedidoFields: [
			    	{key: 'descripcion', label:'Descripción'},
			    	{key: 'cantidad', label:'Cantidad'},
			    	{key: 'precio', label:'Precio'},
			    ],

			    resultadoPedido: {}
			}
		},

		computed:{
			totalRows(){
				return this.dataArray.length;
			},

			selected(){
				return this.bootstrapTable.selected[0];
			},

			getUser: function(){
				return JSON.parse(localStorage.getItem('user'));
			}
		},

		created: function(){
			//SET ID AND KEY
			OpenPay.setId(openpayData.id);
			OpenPay.setApiKey(openpayData.public_key)
			OpenPay.setSandboxMode(true)
			
		},

		mounted: function(){
			this.getProducts();
			this.getPlans();
			this.getDirecciones();
			this.getTarjetasCliente();
			this.getPedidos();
		},
		methods:{
			...mapActions(["getAll","getOne", "create", "update", "delete"]),

			formatoFecha:function(valueFecha){
				return new Date(valueFecha).toLocaleString("es-MX");
			},

			subtotalPedido: function(){
				var subtotal = 0.0;

				this.selectedProducts.forEach(function(product){
					subtotal += product.costo * product.cantidad;
				});

				this.selectedPlans.forEach(function(plan){
					subtotal += plan.cantidad;
				});

				this.subtotalPedidoData = subtotal;
			},
		    onPaginationData (paginationData) {
		      this.$refs.pagination.setPaginationData(paginationData)
		    },
		    onChangePage (page) {
		      this.$refs.vuetable.changePage(page)
		    },
		    rowSelected (items) {
		      this.bootstrapTable.selected = items
		    },

		    getPedidos(){
				var token = JSON.parse(localStorage.getItem('user')).token;
		    	var instance = this;

		    	let payload = {
		    		endpoint: "/api/pedido/cliente",
		    		token: token
		    	}

		    	this.getAll(payload).then(response => {
		    		instance.dataArray = response.data.data;
		    	}).catch(function(error){
		            instance.$toasted.error(error.msg);
		            instance.$parent.toggleLoader();
		        });
		    },

		    getProducts(){
		    	var token = JSON.parse(localStorage.getItem('user')).token;
		    	var instance = this;

		    	let payload = {
		    		endpoint: "/api/producto/todos",
		    		token: token
		    	};

		    	this.getAll(payload).then(response => {
		    		instance.shopProducts = response.data.data;
		    		instance.shopProducts.forEach(element => {
		    			element.img = "/static/img/sole3d.jpeg";
		    		});
		    	}).catch(function(error){
		            instance.$toasted.error(error.msg);
		            instance.$parent.toggleLoader();
		        });
		    },

		    getDirecciones(){
		    	var token = JSON.parse(localStorage.getItem('user')).token;
		    	var instance = this;

		    	let payload = {
		    		endpoint: "/api/direccion-envio",
		    		token: token
		    	};

		    	this.getAll(payload).then(response => {
		    		instance.direccionesList = response.data.data;
		    	}).catch(function(error){
		            instance.$toasted.error(error.msg);
		            instance.$parent.toggleLoader();
		        });
		    },

		    getPlans(){
		    	var token = JSON.parse(localStorage.getItem('user')).token;
		    	var instance = this;

		    	let payload = {
		    		endpoint: "/api/plan/clientes",
		    		token: token
		    	};

		    	instance.$parent.toggleLoader();

		    	this.getAll(payload).then(response => {
		    		
		    		instance.membershipPlans = response.data.data;
		    		instance.membershipPlans.forEach(element => {
		    			element.img = "https://picsum.photos/200";

		    			switch(element.unidadRepeticion){
		    				case "year":
		    					element.unidadEspanol = "años";

		    					if(element.repetirCada == 1){
				    				element.unidadEspanol = element.unidadEspanol.slice(0,-1);
				    			}
		    					break;
		    				case "month":
		    					element.unidadEspanol = "meses";

		    					if(element.repetirCada == 1){
				    				element.unidadEspanol = element.unidadEspanol.slice(0,-2);
				    			}
		    					break;
		    				case "week":
		    					element.unidadEspanol = "semanas";

		    					if(element.repetirCada == 1){
				    				element.unidadEspanol = element.unidadEspanol.slice(0,-1);
				    			}
		    					break;
		    				default:
		    					break;
		    			}

		    		});

		    		instance.$parent.toggleLoader();

		    	}).catch(function(error){
		            instance.$toasted.error(error.msg);
		            instance.$parent.toggleLoader();
		        });
		    },

		    getTarjetasCliente(){
		    	var instance = this;
		    	var token = JSON.parse(localStorage.getItem('user')).token;

		    	let payload = {
		    		endpoint: "/api/card/all",
		    		token: token
		    	}

		    	this.getAll(payload).then(response => {
		    		if(response.data.data != null){
		    			instance.tarjetasCliente = response.data.data;
		    		}		    		
		    	}).catch(function(error){
		            instance.$toasted.error(error.msg);
		            instance.$parent.toggleLoader();
		        });
		    },

		    crearDatosFacturacion(callback){
		    	var instance = this;
		    	var token = JSON.parse(localStorage.getItem('user')).token;

		    	instance.$parent.textLoader("Enviando datos de facturación");

		    	let payload = {
		    		endpoint: "/api/datos-facturacion",
		    		token: token,
		    		data: instance.checkoutForm.facturacion
		    	}

		    	this.create(payload).then(response => {
		    		instance.checkoutForm.idDatosFacturacion = response.data.data.id;
		    		instance.crearPedido();
		    	}).catch(function(error){
		            instance.$toasted.error(error.msg);
		            instance.$parent.toggleLoader();
		        });

		    },

		    checkout(){
		    	//Anti Fraude
		    	var instance = this;
		    	var token = JSON.parse(localStorage.getItem('user')).token;
		    	
		    	instance.$parent.toggleLoader("Creando Pedido");

		    	if(this.banderaFacturacion){
		    		//Si se requiere factura se hace crearDatosFacturacion
		    		this.crearDatosFacturacion();
		    	}else{
		    		this.crearPedido();	
		    	}		    	
		    },

		    crearPedido(){
		    	var instance = this;
		    	var deviceSessionId = OpenPay.deviceData.setup();
		    	var token = JSON.parse(localStorage.getItem('user')).token;
		    	var dataObject = {
	    			idDatosFacturacion: instance.checkoutForm.idDatosFacturacion,
	    			idDireccionEnvio: instance.direccionSelected.id,
	    			productos: instance.selectedProducts,
	    			planes: instance.selectedPlans,
	    			metodoPago: "card",
	    			tokenAntifraude: deviceSessionId,
	    			tipoPago: "contado", //falta programar campos de meses
	    			planPago: 0,
	    			requiereFactura: instance.banderaFacturacion,
	    			requiereEnvio: true,
	    			guardarTarjeta: instance.checkoutForm.guardarTarjeta,
	    			is3dSecure: instance.checkoutForm.is3dSecure
	    		};
	    		let payload = {}

	    		instance.$parent.textLoader("Enviando datos de compra");

		    	this.getTokenCard(function(tokenCard){

		    		if(tokenCard == -1){
		    			//Si hay tarjeta seleccionada no se genera token
		    			dataObject.idTarjeta = instance.tarjetaSelected.id;
		    		}else{
		    			dataObject.tokenTarjeta = tokenCard;
		    		}

			    	payload = {
		    			endpoint: "/api/pedido",
		    			token: token,
		    			data: dataObject
			    	}

			    	instance.create(payload).then(response => {
			    		//instance.$parent.toggleLoader();
			    		
			    		if(response.data.status == 200){
				    		//instance.$toasted.success("Su pedido fue creado con éxito");
				    		//instance.stepTo(4);
				    		instance.resultadoPedido = response;

				    		if(instance.checkoutForm.is3dSecure === true){
				    			window.location.replace(instance.resultadoPedido.data.data.redirect_url);
				    		}else{
				    			instance.steps = 4;
				    			instance.$parent.toggleLoader();
				    			//en esta parte no redirigimos, seguimos con los "steps"
				    		}
				    		
				    		//redirigir a la url retornada por la api
				    		//window.location.href = "http://stackoverflow.com";
			    		}else{
				    		instance.$toasted.error(response.data.error);
				    		console.log("ERROR");
				    		console.log(response);
			    		}			    		
			    	}).catch(error => {
			    		instance.$parent.toggleLoader();
			    		instance.$toasted.error(error.msg);
			    	});
		    	});
		    },

		    checkoutSuscription(){
		    	var instance = this;
		    	var token = JSON.parse(localStorage.getItem('user')).token;
		    	var deviceSessionId = OpenPay.deviceData.setup();
		    	var dataObject = {
		    		idPlan: instance.selectedPlans[0].id,
		    		tokenAntifraude: deviceSessionId
		    	};
		    	let payload = {};

		    	instance.$parent.toggleLoader();
		    	
		    	instance.getTokenCard(function(tokenCard){

			    	if(tokenCard == -1){
		    			//Si hay tarjeta seleccionada no se genera token
		    			dataObject.idTarjeta = instance.tarjetaSelected.id;
		    		}else{
		    			dataObject.tokenTarjeta = tokenCard;
		    		}

			    	payload = {
			    		endpoint: "/api/suscripcion",
			    		token: token,
			    		data: dataObject
			    	}

			    	instance.create(payload).then(response => {

			    		instance.$parent.toggleLoader();
			    		
			    		if(response.data.status == 200){
				    		instance.$toasted.success("Su suscripción fue renovada con éxito");
				    		instance.stepTo(4);
			    		}else{
				    		instance.$toasted.error(response.data.error);	
			    		}
			    		
			    	}).catch(error => {
			    		instance.$parent.toggleLoader();
			    		instance.$toasted.error(error);
			    	});
		    	});
		    },

		    getTokenCard(callback){
		    	var instance = this;

		    	if(instance.tarjetaSelected.id > 0){
		    		callback(-1);
		    	}else{
		    		OpenPay.token.create({
					    "card_number": instance.checkoutForm.cardNumber,
					    "holder_name": instance.checkoutForm.nombre + " " + instance.checkoutForm.apellidos,
					    "expiration_year": instance.checkoutForm.expireyear,
					    "expiration_month": instance.checkoutForm.expiremonth,
					    "cvv2":instance.checkoutForm.ccv,
					    /*"address":{
					        "city":instance.checkoutForm.localidad,
					        "line3":"",
					        "postal_code":instance.checkoutForm.codigoPostal,
					        "line1":instance.checkoutForm.direccion,
					        "line2":"",
					        "state":"",
					        "country_code": instance.checkoutForm.pais
					    }*/
					}, success => {
						callback(success.data.id);
						
					}, error => {
						console.log(error);
					});
		    	}
		    	
		    },

		    stepTo(stepNumber){
		    	
		    	var instance = this;

		    	switch(stepNumber){
		    		case 1:
		    			this.banderaPaso = "producto";
		    			break;
		    		case 2:
		    			this.banderaPaso = "suscripcion";
		    			break;
		    		case 3:
		    			this.subtotalPedido();
		    			break;
		    		default:
		    			break;
		    	}

		    	this.steps = stepNumber;
		    },
		    addSelectedPlan(selected){
		    	var canInsert = true;
		    	var deleteId = 0;

		    	this.selectedPlans.forEach(element =>{
		    		if(element.id == selected.id){
		    			canInsert = false;
		    		}
		    	});

		    	//valida si ya se seleccionó 
		    	if(canInsert){
		    		//selected.cantidad  = 1;
		    		this.selectedPlans.push(selected);	
		    	}
		    	
		    	this.emptyProducts();
		    },
		    addSelected(selected){
		    	var canInsert = true;
		    	var deleteId = 0;

		    	this.selectedProducts.forEach(element =>{
		    		if(element.id == selected.id){
		    			canInsert = false;
		    		}
		    	});

		    	//valida si ya se seleccionó 
		    	if(canInsert){
		    		selected.cantidad  = 1;
		    		this.selectedProducts.push(selected);	
		    	}

		    	this.emptyPlans();//vaciamos los planes seleccionados
		    	this.subtotalPedido();
		    },
		    deleteSelectedPlan(toDelete){
		    	var selectedTemp = this.selectedPlans;
		    	var savedIndex;

		    	this.selectedPlans.forEach(function(element, index){
		    		if(element.id == toDelete.id){
		    			savedIndex = index;
		    		}		    		
		    	});

		    	this.selectedPlans.splice(savedIndex, 1);
		    },
		    deleteSelected(toDelete){
		    	var selectedTemp = this.selectedProducts;
		    	var savedIndex;

		    	this.selectedProducts.forEach(function(element, index){
		    		if(element.id == toDelete.id){
		    			savedIndex = index;
		    		}		    		
		    	});

		    	this.selectedProducts.splice(savedIndex, 1);
		    },
		    emptyPlans(){
		    	this.selectedPlans = [];
		    },
		    emptyProducts(){
		    	this.selectedProducts = [];
		    },
		    hideModal (refname) {
		        this.$refs[refname].hide()

		        if (refname === 'modalnestedinline') {
		          this.$refs['modalnested'].show()
		        }
		    },

		    registrarDireccion(){
		    	var instance = this;
		        var token = JSON.parse(localStorage.getItem('user')).token;
		        let payload = {
		        	endpoint: "/api/direccion-envio",
		        	token: token,
		        	data: instance.nuevaDireccion
		        }

		        instance.$parent.toggleLoader();

		        if(instance.validaDireccionForm()){

			        this.create(payload).then(function(response){
			        	//asignar la nueva dirección como selected
			          	instance.getDirecciones();
			          	instance.direccionSelected = response.data.data;
			          	instance.hideModal('modalDireccion');
			          	instance.$parent.toggleLoader();
			          	instance.$toasted.success("Dirección guardada exitosamente");
			        }).catch(error => {
			        	instance.$parent.toggleLoader();
			        	instance.$toasted.error(error);
			        });
			    }else{
			    	instance.$toasted.error("Favor de llenar los campos obligatorios (*) de dirección");
			    }
		    },

		    validaDireccionForm(){
		    	var bandera = true;
		    	var instance = this;

		    	for(var campoDir in instance.nuevaDireccion) {
				    
				    switch(campoDir){
				    	case "calle":
				    	case "numExt":
				    	case "estadoGeografico":
				    	case "codigoPostal":
				    		if(instance.nuevaDireccion[campoDir] == ""){
				    			bandera = false;
				    		}
				    		break;
				    }
				}

				return bandera;
		    },

		    seleccionarDireccion(direccion){
		    	this.direccionSelected = direccion;
		    },

		    seleccionarTarjeta(tarjeta){
		    	this.tarjetaSelected = tarjeta;
		    },

		    desSeleccionarTarjeta(){
		    	this.tarjetaSelected = {
			    	id: -1
			    }
		    },

		    eliminarDireccion(direccion){
		    	this.direccionSelected = {
			    	id: -1
			    };

			    var instance = this;
		        var token = JSON.parse(localStorage.getItem('user')).token;
		        let payload = {
		        	endpoint: "/api/direccion-envio/" + direccion.id,
		        	token: token,
		        }

		        instance.$parent.toggleLoader();

		        this.delete(payload).then(function(response){
		        	//asignar la nueva dirección como selected
		          	instance.getDirecciones();
		          	instance.direccionSelected = response.data.data;
		          	//instance.hideModal('modalDireccion');
		          	instance.$parent.toggleLoader();
		          	instance.$toasted.success("Dirección eliminada exitosamente");
		        }).catch(error => {
		        	instance.$parent.toggleLoader();
		        	instance.$toasted.error(error);
		        });

		    },

		    actualizaCantidades(){
		    	//this.selectedProducts
		    	this.subtotalPedido();
		    },

		    tarjetaFormValidate(){
		    	var isValid = false;
		    	

		    	if(this.checkoutForm.nombre.length > 0 && this.checkoutForm.apellidos.length > 0 && this.checkoutForm.cardNumber.length > 0
		    		&& this.checkoutForm.expiremonth.length == 2 && this.checkoutForm.expireyear.length == 2){
		    		isValid = true;
		    	}

		    	return isValid;

		    },

		    formatterCard(tarjetaNum){
		    	let matches = tarjetaNum.match(/\d{1,16}/g);
		    	let returnVal = "";

		    	if(matches != null){
		    		returnVal = matches[0];
		    	}

		    	return returnVal;
		    },

		    formatterMonthYear(tarjetaMonth){
		    	let matches = tarjetaMonth.match(/\d{1,2}/g);
		    	let returnVal = "";

		    	if(matches != null){
		    		returnVal = matches[0];
		    	}

		    	return returnVal;
		    },

		    formatterCVV(tarjetaMonth){
		    	let matches = tarjetaMonth.match(/\d{1,4}/g);
		    	let returnVal = "";

		    	if(matches != null){
		    		returnVal = matches[0];
		    	}

		    	return returnVal;
		    },
		}
	}
</script>

<style>
#tablaDirecciones .direccionCard:hover{
	background-color: #f3f3f3;
}

#tablaDirecciones .direccionCard.selected{
	background-color: #95dcee
}

#tablaDirecciones .direccionCard{
	cursor: pointer;
	margin-top: 1em;
	width: 100%;
}

#tablaDirecciones > div{
	display: flex;
}

.carritoContainer{
	overflow: scroll;
}

#carrito div p{
	text-align: right;
}

#carrito div p strong{
	padding-right: 2em;
}

#carrito article{
	display: flex;
	align-items: center;
}

#carrito article img{
	width: 65px;
}

#carrito article span{
	flex: auto;
	padding-left: 1em;
}

#carrito article input{
	width: 70px;
}

#carrito article p{
	flex: auto;
}

#carrito .sticky{
	position: sticky;
	top: 120px;
}

.casilla{
	align-items: center;
	display: flex;	
}

#msgGracias{
	padding: 2em;
	border: 2px solid rgba(72, 189, 19, 0.7);
	background-color: rgba(72,189,19,0.1);
	text-align: center;
	font-size: 1.5em;
}

#whatsappLogo{
	width: 30px;
}

#logoGracias{
	width: 120px;
}

button.addDirection{
	width: 100%;
}

.openpaylogo{
	text-align: left;
}

.openpaylogo img{
	width: 200px;
}

#logoVisaPlaceholder{
	width: 70px;
	position: absolute;
	top: 3px;
	right: 7px;
}

.txt-right{
	text-align: right;
}

.eliminarDireccion{
	color: black;
	position: absolute;
	top: 0;
	right: 0;
	width: 30px;
	height: 30px;
	font-size: 17pt;
	cursor: pointer;
}

.imgCell{
	padding-bottom: 35px; 
	padding-top: 15px; 
	border-top: 0;
	width: 100% !important;
}

.invoice{
	justify-content: center;
}

.invoice .invoice-contents{
	padding: 0px;
	height: auto;
	overflow: scroll;
}

.tableStyles{
	width: 100%; 
	background-color: #ffffff;
	border-collapse: separate !important;
	border-spacing: 0;
	color: #242128;
	margin: 0;
	padding: 30px;
}

.headerInvoice{
	color: #8f8f8f;
	font-weight: normal;
	line-height: 1.2;
	font-size: 12px;
	white-space: nowrap; 
}
</style>